import React, { Suspense, useCallback, useMemo } from "react";
import styles from "./bubbles-list.styles.css";
import moment from "moment";
import { debounce } from "lodash";
const CpButton = React.lazy(() =>
  SystemJS.import("canopy-styleguide!sofe").then((sg) =>
    Promise.resolve({ default: sg.CpButton })
  )
);

export default function IndividualFeatureBubble(props) {
  const { bubble, bottomHR, close } = props;
  let expirationDate;
  if (bubble.expirationDate) {
    expirationDate = moment(bubble.expirationDate);
  }
  const isOptOut = bubble.strategy === "opt-out";

  const daysUntilExpire = useMemo(() => {
    return expirationDate?.diff(moment(), "days") || Infinity;
  }, [bubble.expirationDate]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has a missing dependency: 'expirationDate'

  const learnMoreLink = bubble.documentationUrl ? (
    <a href={bubble.documentationUrl} target="_blank" rel="noopener noreferrer">
      Learn More
    </a>
  ) : null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    // lint-td:  useCallback received a function whose dependencies are unknown. Pass an inline function instead
    debounce(
      (value, days) => {
        bubble.update(value, days);
        close();
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    []
  );

  return (
    <div className={styles.featureChoice}>
      <Suspense fallback={null}>
        <div className="cps-subheader-sm cps-color-vibrant-ocean cps-margin-bottom-16 cps-padding-right-8">
          {bubble.title}
        </div>
        {bubble.message ? (
          <div>{bubble.message}</div>
        ) : (
          <>
            <div className={styles.actionBlock}>
              {bubble.active ? (
                isOptOut ? (
                  <div className={styles.primaryAction}>
                    <div>Delay this feature for</div>
                    <div className={`cp-mv-8`}>
                      {daysUntilExpire < 3 && (
                        <CpButton
                          btnType="flat"
                          onClick={() => debouncedUpdate(false, 1)}
                        >
                          <strong>1 day</strong>
                        </CpButton>
                      )}
                      {daysUntilExpire >= 3 && (
                        <CpButton
                          btnType="flat"
                          onClick={() => debouncedUpdate(false, 3)}
                        >
                          <strong>3 days</strong>
                        </CpButton>
                      )}
                      {daysUntilExpire >= 7 && (
                        <CpButton
                          btnType="flat"
                          onClick={() => debouncedUpdate(false, 7)}
                        >
                          <strong>7 days</strong>
                        </CpButton>
                      )}
                      {daysUntilExpire >= 15 && (
                        <CpButton
                          btnType="flat"
                          onClick={() => debouncedUpdate(false, 15)}
                        >
                          <strong>15 days</strong>
                        </CpButton>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.primaryAction}>
                    <CpButton
                      btnType="primary"
                      className={`cp-mb-8 ${styles.newVersion}`}
                      onClick={() => debouncedUpdate(false)}
                    >
                      Return to default
                    </CpButton>
                  </div>
                )
              ) : (
                <div className={styles.primaryAction}>
                  <CpButton
                    btnType="primary"
                    className={`cp-mb-8 ${styles.newVersion}`}
                    onClick={() => debouncedUpdate(true)}
                  >
                    Try it out
                  </CpButton>
                </div>
              )}
            </div>
            {expirationDate ? (
              isOptOut ? (
                <div>
                  You can delay this feature, with the option to return to the
                  new experience, until{" "}
                  <strong>{expirationDate.format("LL")}</strong>.{" "}
                  {learnMoreLink}
                </div>
              ) : (
                <div>
                  You can return to the {bubble.active ? "default" : "current"}{" "}
                  experience at anytime until{" "}
                  <strong>{expirationDate.format("LL")}</strong>.{" "}
                  {learnMoreLink}
                </div>
              )
            ) : isOptOut ? (
              <div>
                This option will only be accessible for a short time.{" "}
                {learnMoreLink}
              </div>
            ) : (
              <div>
                {bubble.active
                  ? `This option will be available`
                  : `You'll be able to return to the current experience`}{" "}
                through tax season. {learnMoreLink}
              </div>
            )}
          </>
        )}
        {bottomHR ? <hr /> : null}
      </Suspense>
    </div>
  );
}
